import axios from "axios";
import "../api/axiosConfig.js";
import { PublicClientApplication } from "@azure/msal-browser";
export default {
  headerCongifg() {
    let userProfile = JSON.parse(sessionStorage.getItem("userProfile"));

    if (userProfile) {
      return {
        headers: {
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("userProfile")).id_token,
          "Content-Type": "application/json"
        }
      };
    } else {
      if (this.$oidc && this.$oidc.userProfile) {
        return {
          headers: {
            Authorization: "Bearer " + this.$oidc.userProfile.id_token,
            "Content-Type": "application/json"
          }
        };
      }
    }
  },
  async httpCallDetails(url, method, body) {
    method = method.toLowerCase();
    switch (method) {
      case "get": {
        const response = await this.get(url);
        return this.postCall(response);
      }
      case "post": {
        const response = await this.post(url, body);
        return this.postCall(response);
      }
      case "put": {
        const response = await this.put(url, body);
        return this.postCall(response);
      }
      case "delete": {
        const response = await this.delete(url);
        return this.postCall(response);
      }
      default:
        break;
    }
  },
  async get(url) {
    try {
      let userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
      if ((this.$oidc && this.$oidc.userProfile) || userProfile) {
        return await axios.get(url, this.headerCongifg());
      }
    } catch (error) {
      return error.response;
    }
  },
  async post(url, payload) {
    let headers = this.headerCongifg();
    if (url.indexOf("CreateDocument") !== -1) {
      headers.headers.ContentType = "multipart/form-data";
    }
    let userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
    if ((this.$oidc && this.$oidc.userProfile) || userProfile) {
      return await axios.post(url, payload, headers);
    }
  },
  async put(url, payload) {
    let userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
    if ((this.$oidc && this.$oidc.userProfile) || userProfile) {
      return await axios.put(url, payload, this.headerCongifg());
    }
  },
  async delete(url) {
    let userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
    if ((this.$oidc && this.$oidc.userProfile) || userProfile) {
      return await axios.delete(url, this.headerCongifg());
    }
  },
  postCall(response) {
    if (response) {
      const data = response.data ? response.data : "";
      if (response.status === 401) {
        return this.errorHandling(response);
      } else if (data !== "") {
        return data;
      } else {
        return this.errorHandling(response);
      }
    }
    return this.errorHandling(response);
  },
  async errorHandling(response) {
    if (response && response.status === 401) {
      alert("Session Expired");
      if (this.$store) {
        let config = this.$store.getters.stateMSAL.msalConfig;
        const myMsal = new PublicClientApplication(config);
        let homeAccountId = JSON.parse(sessionStorage.getItem("userProfile")).oid + "." + JSON.parse(sessionStorage.getItem("userProfile")).tid;
        // you can select which account application should sign out
        const logoutRequest = {
          account: myMsal.getAccountByHomeId(homeAccountId)
        };

        myMsal.logoutRedirect(logoutRequest);
        sessionStorage.clear();
      }
    }

    return response;
  }
};
