<template>
  <n-dialog-provider>
    <n-message-provider>
      <div>
        <MainComponent v-if="$store.state.accessToken" />
      </div>
    </n-message-provider>
  </n-dialog-provider>
</template>
<script>
  import MainComponent from "./MainComponent.vue";
  import { PublicClientApplication } from "@azure/msal-browser";
  import { NDialogProvider, NMessageProvider } from "naive-ui";

  export default {
    name: "App",
    components: {
      MainComponent,
      NDialogProvider,
      NMessageProvider
    },
    data() {
      return {
        account: undefined
      };
    },
    beforeCreate() {
      if (!sessionStorage || !sessionStorage.getItem("userProfile")) {
        localStorage.clear();
      }
    },
    async created() {
      this.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig);
      this.SignIn();
    },
    beforeMount() {
      const accounts = this.$msalInstance.getAllAccounts();
      if (accounts.length == 0) {
        return;
      }
      this.account = accounts[0];
      this.$emitter.emit("login", this.account);
    },
    async mounted() {},
    methods: {
      async SignIn() {
        this.$msalInstance.handleRedirectPromise().then(this.handleResponse);
      },
      jsonConcat(o1, o2) {
        for (let key in o2) {
          o1[key] = o2[key];
        }
        return o1;
      },
      handleResponse(response) {
        if (response !== null && response) {
          // Acquire token silent success
          let accessToken = response.accessToken;
          this.$store.dispatch("accessToken", accessToken);
          this.$store.commit("setAccessToken", response.accessToken);
          this.accountId = response.account.homeAccountId;
        } else {
          // In case multiple accounts exist, you can select
          const currentAccounts = this.$msalInstance.getAllAccounts();
          if (currentAccounts.length === 0) {
            // no accounts signed-in, attempt to sign a user in
            this.$msalInstance.loginRedirect({});
          } else if (currentAccounts.length === 1) {
            this.accountId = currentAccounts[0].homeAccountId;
            this.account = this.accountId;
            this.$emitter.emit("login", this.account);
            let accessToken = JSON.parse(sessionStorage.getItem("userProfile")).access_token;
            this.$store.dispatch("accessToken", accessToken);
            this.$store.commit("setAccessToken", JSON.parse(sessionStorage.getItem("userProfile")).access_token);
          }
        }
        if (response && response.idToken) {
          let accessToken = this.parseJwt(response.accessToken);
          let userProfile = this.parseJwt(response.idToken);
          userProfile = this.jsonConcat(userProfile, accessToken);
          userProfile = this.jsonConcat(userProfile, {
            isAuthenticated: true,
            id_token: response.idToken,
            access_token: response.accessToken
          });
          this.$oidc.userProfile = userProfile;
          this.$oidc.isAuthenticated = true;
          sessionStorage.setItem("userProfile", JSON.stringify(this.$oidc.userProfile));

          if (userProfile.roles.length > 0) {
            this.$oidc.userProfile.isAuthenticated = true;
          } else {
            this.$oidc.userProfile.isAuthenticated = false;
          }

          this.$router.push("/dashboard");
          sessionStorage.setItem("userProfile", JSON.stringify(this.$oidc.userProfile));
        }
      },
      parseJwt(token) {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        return JSON.parse(jsonPayload);
      },
      async SignIns() {
        await this.$msalInstance
          .loginRedirect({})
          .then((q) => {
            console.log("loginRedirect", q);
            const myAccounts = this.$msalInstance.getAllAccounts();
            this.account = myAccounts[0];
            this.$emitter.emit("login", this.account);
          })
          .catch((error) => {
            console.error(`error during authentication: ${error}`);
          });
      },
      async SignOut() {
        await this.$msalInstance
          .logout({})
          .then(() => {
            this.$emitter.emit("logout", "logging out");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };
</script>
<style>
  @import url(./common/css/main.css);
</style>
