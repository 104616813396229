<template>
  <n-config-provider :theme-overrides="themeOverrides" v-if="$oidc && $oidc.userProfile && $oidc.userProfile.isAuthenticated">
    <HeaderComp v-if="$oidc && $oidc.userProfile && $oidc.userProfile.isAuthenticated" />
    <router-view v-if="$oidc && $oidc.userProfile && $oidc.userProfile.isAuthenticated" />
    <FooterComp v-if="$oidc && $oidc.userProfile && $oidc.userProfile.isAuthenticated" />
    <n-global-style />
  </n-config-provider>
  <n-config-provider :theme-overrides="themeOverrides" v-else> Do not have access </n-config-provider>
</template>

<script>
  import { NConfigProvider, NGlobalStyle } from "naive-ui";
  import HeaderComp from "./components/HeaderComponent.vue";
  import FooterComp from "./components/FooterComponent.vue";
  import ThemeOverrides from "@/common/theme/index";

  /**
   * Use this for type hints under js file
   * @type import('naive-ui').GlobalThemeOverrides
   */

  export default {
    name: "MainComponent",
    components: {
      HeaderComp,
      FooterComp,
      NConfigProvider,
      NGlobalStyle
    },
    data: () => ({
      themeOverrides: ThemeOverrides
    }),
    created() {
      this.$oidc.userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
    },
    beforeMount() {
      this.$oidc.userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
      console.log(this.$oidc.userProfile);
      if (this.$oidc.userProfile && this.$oidc.userProfile.roles && this.$oidc.userProfile.roles.length) {
        this.$oidc.isAuthenticated = true;
      } else {
        this.$oidc.isAuthenticated = false;
      }
    },
    mounted() {
      this.$oidc.userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
      if (this.$oidc.userProfile) {
        this.$oidc.isAuthenticated = true;
      } else {
        this.$oidc.isAuthenticated = false;
      }
      console.log("->", this.$route);
      if (this.$route.path === "/track-alert") this.$router.push(this.$route.fullPath);
      else this.$router.push("/");
    }
  };
</script>
