<template>
  <div class="menu-container">
    <div class="single-item" :class="{ 'sub-menu-item': subMenu }" v-for="eachMenu in menuList" :key="eachMenu.menuId">
      <span :class="{ 'sub-menu-item-inner': subMenu }">
        <div v-if="eachMenu.subMenu && eachMenu.subMenu.length && selectedMenu.menu !== eachMenu.menuId" style="display: flex; justify-content: center; align-items: center">
          <n-dropdown trigger="hover" :options="eachMenu.subMenu" @select="handleSelect" :render-label="renderLabelFn">
            <div class="menu-drop-down menu-item">
              {{ eachMenu.menuName }}
              <n-icon class="notification-icon" size="20" :component="ChevronDownOutline"></n-icon>
            </div>
          </n-dropdown>
        </div>
        <div
          class="menu-item"
          @click="handleSelect(eachMenu.key)"
          v-else
          :class="{
            'sd-color': selectedMenu.menu == eachMenu.menuId,
            'menu-item-active': selectedMenu.menu == eachMenu.menuId
          }"
        >
          {{ eachMenu.menuName }}
        </div>
      </span>
      <hr v-if="subMenu" class="opacity-zero" :class="{ 'active-tab-hr': selectedMenu.menu == eachMenu.menuId }" />
    </div>
  </div>
</template>
<script>
  import { NIcon, NDropdown } from "naive-ui";
  import { ChevronDownOutline } from "@vicons/ionicons5"; //https://www.xicons.org/#/
  import { h } from "vue";
  export default {
    name: "MenuList",
    components: {
      NIcon,
      NDropdown
    },
    props: {
      menuList: [],
      selectedMenu: [],
      subMenu: Boolean
    },
    data: () => ({
      ChevronDownOutline
    }),
    methods: {
      handleSelect(key) {
        if (key) this.$router.push(`/${key}`);
        else console.log(key);
      },
      renderLabelFn(option) {
        console.log(option);
        let spanNodes = option.label.split(" ").map((i) => h("span", { style: "font-family:" + (i === "New" ? "'BarlowB'" : "'BarlowR'") }, { default: () => i + " " }));
        return h("span", {}, spanNodes);
      }
    }
  };
</script>
<style scoped>
  .menu-item-active {
    cursor: auto !important;
  }

  .opacity-zero {
    opacity: 0;
  }

  .active-tab-hr {
    margin: 0 40%;
    padding: 0;
    border-top: 0.25rem #ffe500 solid;
    width: 20%;
    opacity: 1 !important;
  }

  .sub-menu-item-inner {
    height: 94%;
    display: flex;
    align-items: center;
  }
</style>
