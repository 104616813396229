<template>
  <span>
    <header class="header" style="border-top: #ffe500 solid 3px" :class="{ 'extra-height': !subMenuList.length }">
      <n-space justify="space-between" class="inner-header">
        <div class="img-container" @click="$router.push('/dashboard')">
          <img style="height: 4.5rem; cursor: pointer" src="@/assets/logo-header.svg" alt="" srcset="" />
        </div>
        <div class="menu-profile-container">
          <MenuList :menuList="menuList" :selectedMenu="selectedMenu" :subMenu="false" />
          <Profile />
        </div>
      </n-space>
    </header>
    <div class="sub-menu-container header" v-if="subMenuList.length">
      <MenuList :menuList="subMenuList" :selectedMenu="{ menu: selectedMenu.submenu }" :subMenu="true" />
    </div>
  </span>
</template>

<script>
  import { NSpace } from "naive-ui";
  import { mapGetters } from "vuex";
  import MenuList from "./MenuList";
  import Profile from "./ProfileSection";
  import ApiCalls from "@/common/api/apiCalls";
  import Api from "@/common/constants/api";
  import roles from "@/common/constants/roles";
  import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";

  export default {
    name: "HeaderComp",
    components: { NSpace, MenuList, Profile },
    computed: {
      ...mapGetters(["selectedMenu", "loggedInUserData"]),
      subMenuList() {
        let subMenu = [];
        this.menuList.forEach((menu) => {
          if (Number(this.selectedMenu.menu) === Number(menu.menuId) && menu.subMenu && menu.subMenu.length)
            menu.subMenu.forEach((temp) => {
              subMenu.push(temp);
            });
        });
        return subMenu;
      }
    },
    data: () => ({
      menuList: [],
      rawMenu: [
        {
          label: "Home",
          key: "dashboard",
          disabled: false,
          menuId: 4,
          menuName: "Home",
          visibleToRoles: "ALL"
        },
        {
          menuId: 1,
          menuName: "EHS MSR/ Safety Alert",
          subMenu: [
            {
              label: "New EHS MSR",
              key: "create-ehs-msr",
              disabled: false,
              menuId: 1,
              menuName: "New EHS MSR",
              visibleToRoles: [roles.GLOBAL, roles.GLOBALRELIABILITY, roles.ADMIN]
            },
            {
              label: "View EHS MSR",
              key: "view-ehs-msr",
              disabled: false,
              menuId: 2,
              menuName: "View EHS MSR",
              visibleToRoles: "ALL"
            },
            {
              label: "New Safety Alert",
              key: "create-alert",
              disabled: false,
              menuId: 3,
              menuName: "New Safety Alert",
              visibleToRoles: [roles.GLOBAL, roles.GLOBALRELIABILITY, roles.ADMIN]
            },
            {
              label: "View Safety Alert",
              key: "view-alert",
              disabled: false,
              menuId: 4,
              menuName: "View Safety Alert",
              visibleToRoles: "ALL"
            }
          ]
        },
        {
          label: "Worklist",
          key: "worklist",
          disabled: false,
          menuId: 2,
          menuName: "Worklist",
          visibleToRoles: [roles.ENGINEERINGMANAGER, roles.PLANTMANAGER, roles.MAINTENANCEMANAGER]
        },
        {
          menuId: 3,
          menuName: "Admin",
          subMenu: [
            {
              label: "Configuration",
              key: "configure-data",
              disabled: false,
              menuId: 1,
              menuName: "Configuration",
              visibleToRoles: [roles.GLOBAL, roles.GLOBALRELIABILITY, roles.ADMIN]
            }
          ]
        },
        {
          label: "Help",
          key: "help",
          disabled: false,
          menuId: 5,
          menuName: "Help",
          visibleToRoles: "ALL"
        }
      ]
    }),
    created() {
      this.getUserDetail();
      this.onAlertToggle();
      setInterval(() => {
        this.getAccessToken();
      }, 5 * 60 * 1000);
    },
    methods: {
      onAlertToggle() {
        let type = "EHS MSR";
        this.$store.commit("showLoader", true);
        console.log(type);
        ApiCalls.httpCallDetails(Api.SETUSERPREFERENCE, "post", {
          preference: type
        }).then((data) => {
          console.log(data);
          this.$store.commit("alertType", false);
          this.getUserDetail();
        });
      },
      async getAccessToken() {
        const msalInstance = new PublicClientApplication(this.$store.getters.stateMSAL.msalConfig);
        const redirectResponse = await msalInstance.handleRedirectPromise();
        if (redirectResponse !== null && redirectResponse) {
          let accessToken = redirectResponse.accessToken;
          this.$store.dispatch("accessToken", accessToken);
        } else {
          // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
          let currentAccount = msalInstance.getAccountByUsername(this.$oidc.userProfile.email);
          console.log(JSON.parse(JSON.stringify(currentAccount.idTokenClaims)));

          let silentRequest = {
            scopes: ["User.Read"],
            account: currentAccount,
            forceRefresh: true
          };

          let request = {
            scopes: ["User.Read"],
            loginHint: currentAccount.username // For v1 endpoints, use upn from idToken claims
          };

          msalInstance.setActiveAccount();
          msalInstance
            .acquireTokenSilent(silentRequest)
            .then(this.handleResponse)
            .catch(function (error) {
              console.error(error);
              if (error instanceof InteractionRequiredAuthError) msalInstance.acquireTokenRedirect(request);
            });
        }
      },
      handleResponse(tokenRequested) {
        if (tokenRequested !== null && tokenRequested) {
          let response = tokenRequested;
          if (response && response.idToken) {
            let idToken = this.parseJwt(response.idToken);
            let accessToken = this.parseJwt(response.accessToken);
            let userProfile = idToken;
            userProfile = this.jsonConcat(userProfile, accessToken);
            userProfile = this.jsonConcat(userProfile, {
              isAuthenticated: true,
              id_token: response.idToken,
              access_token: response.accessToken
            });
            this.$oidc.userProfile = userProfile;
            this.$oidc.isAuthenticated = true;

            sessionStorage.setItem("userProfile", JSON.stringify(this.$oidc.userProfile));
            this.$store.dispatch("accessToken", accessToken);
            this.$store.commit("setAccessToken", accessToken);

            this.getUserDetail();
          }
        }
      },
      jsonConcat(o1, o2) {
        for (let key in o2) {
          o1[key] = o2[key];
        }
        return o1;
      },
      parseJwt(token) {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        return JSON.parse(jsonPayload);
      },
      getUserDetail() {
        ApiCalls.httpCallDetails(Api.GETUSERDETAILS, "get").then((data) => {
          let tempMenu = [];
          if (this.$route.path === "/")
            if (data.data && data.data.roles && data.data.roles.length) this.$router.push("/dashboard");
            else this.$router.push("/help");

          let userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
          userProfile["roles"] = data.data.roles.map((r) => r.roleCode.toUpperCase());
          userProfile["userId"] = data.data.userId;
          userProfile["userPlantId"] = data.data.plantId;
          userProfile["userRegionId"] = data.data.regionId;
          this.$oidc.userProfile = JSON.parse(JSON.stringify(userProfile));
          sessionStorage.setItem("userProfile", JSON.stringify(userProfile));

          if (userProfile["roles"] && userProfile["roles"].length) {
            this.rawMenu.forEach((menu) => {
              let tempObj = JSON.parse(JSON.stringify(menu));

              // eslint-disable-next-line no-prototype-builtins
              if (menu.hasOwnProperty("subMenu")) {
                tempObj.subMenu = [];
                menu.subMenu.forEach((temp) => {
                  if (temp.visibleToRoles === "ALL") tempObj.subMenu.push(temp);
                  else
                    temp.visibleToRoles.forEach((role) => {
                      let index = tempObj.subMenu.findIndex((i) => i.key === temp.key);
                      if (userProfile.roles.includes(role) && index === -1) tempObj.subMenu.push(temp);
                    });
                });
                if (tempObj.subMenu.length) tempMenu.push(tempObj);
              } // eslint-disable-next-line no-prototype-builtins
              else if (menu.hasOwnProperty("visibleToRoles")) {
                if (menu.visibleToRoles === "ALL") tempMenu.push(menu);
                else {
                  let flag = false;
                  menu.visibleToRoles.forEach((role) => {
                    if (userProfile.roles.includes(role)) flag = true;
                  });
                  if (flag) tempMenu.push(menu);
                }
              }
            });
          }
          this.menuList = tempMenu;
        });
      }
    }
  };
</script>
<style scoped>
  .header {
    height: 5.5rem;
    background-color: #274ba7;
    align-items: center;
    gap: 0 !important;
    padding: 0 3rem;
  }

  .inner-header {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    height: 5rem;
    background-color: #274ba7;
    align-items: center;
    gap: 0 !important;
    padding: 0 2rem;
  }

  .img-container {
    height: 5.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menu-profile-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: inherit;
  }

  .sub-menu-container {
    border: 1px solid #6178b5;
    height: 5.5rem;
  }

  header > div > div {
    height: 5rem;
  }

  header > div > div:nth-child(1) {
    width: 25%;
  }

  header > div > div:nth-child(2) {
    width: 75%;
  }

  .logo-name {
    padding: 0.5rem;
    font-family: "BarlowSb", sans-serif;
    font-size: 1.5rem;
    color: #fff;
  }

  .extra-height {
    height: 7.5rem;
  }
</style>
