import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Dashboard/DashboardScreen.vue")
    },
    {
      path: "/dashboard",
      name: "home",
      component: () => import("@/views/Dashboard/DashboardScreen.vue")
    },
    {
      path: "/about",
      name: "about",
      component: () => import("@/views/Dashboard/HelloWorld.vue")
    },
    {
      path: "/userProfile",
      name: "userProfile",
      component: () => import("@/views/Profile/UserProfile.vue")
    },
    {
      path: "/edit-my-profile",
      name: "edit-my-profile",
      component: () => import("@/views/Profile/EditMyProfile.vue")
    },
    {
      path: "/configure-user",
      name: "configure-user",
      component: () => import("@/views/Profile/ConfigureProfile.vue")
    },
    {
      path: "/configure-user-profile",
      name: "configure-user-profile",
      component: () => import("@/views/Profile/ConfigureUserProfile.vue")
    },
    {
      path: "/create-ehs-msr",
      name: "create-ehs-msr",
      component: () => import("@/views/CreateAlert/CreateAlert.vue")
    },
    {
      path: "/create-alert",
      name: "create-alert",
      component: () => import("@/views/CreateAlert/CreateAlert.vue")
    },
    {
      path: "/view-ehs-msr",
      name: "view-ehs-msr",
      component: () => import("@/views/ViewAlert/ViewAlerts.vue")
    },
    {
      path: "/view-alert",
      name: "view-alert",
      component: () => import("@/views/ViewAlert/ViewAlerts.vue")
    },
    {
      path: "/edit-alert",
      name: "edit-alert",
      component: () => import("@/views/EditAlert/EditAlert.vue")
    },
    {
      path: "/configure-data",
      name: "configure-data",
      component: () => import("@/views/AdminAlert/ProcessArea-Equipments.vue")
    },
    {
      path: "/worklist",
      name: "worklist",
      component: () => import("@/views/Worklist/RoleBasedWorklist.vue")
    },
    {
      path: "/respond-alert",
      name: "respond-alert",
      component: () => import("@/views/RespondAlert/RespondAlert.vue")
    },
     {
      path: "/respond-ehs-msr",
      name: "respond-ehs-msr",
      component: () => import("@/views/RespondAlert/RespondAlert.vue")
    },
    {
      path: "/track-alert",
      name: "track-alert",
      component: () => import("@/views/TrackAlert/Track-Alert.vue"),
      props: (route) => ({ query: route.query.id })
    },
    {
      path: "/track-ehs-msr",
      name: "track-ehs-msr",
      component: () => import("@/views/TrackAlert/Track-Alert.vue"),
      props: (route) => ({ query: route.query.id })
    },
    {
      path: "/help",
      name: "help",
      component: () => import("@/views/Help/HelpScreen.vue"),
      props: (route) => ({ query: route.query.id })
    }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  ]
});

window.router = router;

router.beforeEach((to, from, next) => {
  console.log("from", from.fullPath, "going to", to.fullPath);
  if (to.query.wait) {
    setTimeout(() => next(), 100);
  } else if (to.query.redirect) {
    next(to.query.redirect);
  } else if (to.query.abort) {
    next(false);
  } else {
    next();
  }
});

export default router;
