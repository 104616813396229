export default {
  GETREGIONLIST: "Dropdown/GetRegionList",
  GETPLANTLIST: "Dropdown/GetPlantList",
  GETPROCESSAREALIST: "Dropdown/GetProcessAreaList",
  GETEQUIPMENTLIST: "Dropdown/GetEquipmentListByProcessId/",
  GETFREQUENCYLIST: "Dropdown/GetAlertFrequencies",
  GETALERTYPELIST: "Dropdown/GetAlertTypes",
  SAVEALERTINFO: "Alert/UpsertAlertInformation",
  SAVEALERTEXPECTATIONS: "Alert/SaveAlertExpectations/",
  GETEXPECTATIONTYPES: "Dropdown/GetExpectationTypes",
  GETALERTLISTBYSYATUSGLOBAL: "Alert/GetAlertListByStatusGlobal",
  GETALERTLISTBYTYPE:"Alert/GetAlertListByType",
  GETALERTDETAILS: "Alert/GetAlertDetails/",
  SETUSERPREFERENCE: "User/SetUserPreference",
  MARKALERTASDRAFT: "Alert/MarkAlertAsSubmited/",
  PUBLISHTOPLANT: "Alert/ReleaseAlertToPlants/",
  ADDORUPDATEEQUIPMENT: "Admin/AddOrUpdateEquipment",
  GETALLPROCESSANDEQUIPMENTS: "Dropdown/AllEquipmentList",
  ADDNEWPROCESSAREA: "Admin/SaveProcessArea",
  GETRELEASEDPLANTS: "Alert/GetAlertRealeasedPlantList/",
  DELETEEQUIPMENT: "Admin/deleteEquipment",
  DELETEALERT: "Alert/DeleteAlert",
  TRACKALERTPROGRESS: "Workflow/TrackAlertProgress",
  ALLEQUIPMENT: "Dropdown/AllEquipmentList",
  TRACLALERTSTATUS: "Dropdown/StatusDropdownByStatusType?StatusType=AlertPlantMapper",
  GETWORKLIST: "Workflow/GetAlertWorklistByUser",
  GETSTATUSBYTYPE: "Dropdown/StatusDropdownByStatusType",
  GETYEARFORTRACKALERT: "Dropdown/GetYearDropdownForTrackAlert",
  SAVEPLANT: "Admin/SavePlant",
  GETEXPECTATIONPROGRESS: "Workflow/GetExpectationProgressByMapperId/",
  SAVEEVIDENCE: "Workflow/SaveWorkflowEvidences",
  SAVEDELEGATE: "Delegation/DelegateByEmail",
  GETDELEGATE: "Delegation/GetDelegatedUserByAlertId/",
  SAVEEXPECTATIONUPDATE: "Workflow/SaveAlertWorkflowProgress",
  REPONCESTATUSDROPDOWN: "Dropdown/GetWorkflowHistoryStatus",
  SUBMITFORAPPROVAL: "Workflow/SubmitAlertForApproval",
  GETHISTORY: "Workflow/GetExpectationHistoryByAlertId/",
  GETUSERDETAILS: "User/GetUserDetails",
  GETPLANTTYPE: "Dropdown/GetPlantTypeDropdown",
  GETEVIDENCEBYMAPPER: "Workflow/GetEvidenceByMapperId/",
  GETPBULIST: "Dropdown/GetPlantPBUDropdown",
  GETDASBOARDALERTCOUNT: "Dashboard/GetOverallAlertCounts",
  GETALLREGIONALCOUNT: "Dashboard/GetAllRegionalCount",
  GETFILTERWISECOUNT: "Dashboard/GetAllAlertListDashboardV2",
  GETUSERLIST: "Dropdown/GetUserListFromMyPlant/",
  GETROUTEOPTION: "Dropdown/GetAlertRouteDropdown",
  SAVEAPPROVALDETAIL: "Workflow/SaveAlertApprovalOrSendBack",
  DELETEEVIDENCE: "Alert/RemoveCtiDocument/",
  GETUSERGROUP: "SyncUser/KnowMyUserGroups"
};
