import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: "9077c437-bcf0-46fb-ae7a-60c6fdd80855", //EHS
          authority: "https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0"
        },
        cache: {
          cacheLocation: "sessionStorage"
        }
      },
      accessToken: "",
      selectedMenu: {
        main: 0,
        subMenu: 0
      },
      breadcrumb: {
        title: "",
        breadcrumb: []
      },
      showLoader: false,
      workingOnAlert: {},
      alertType: false //false=EHS , true  = Safety
      , disableAlertType: false
    };
  },
  getters: {
    stateMSAL() {
      return {
        msalConfig: {
          auth: {
            clientId: "9077c437-bcf0-46fb-ae7a-60c6fdd80855", //EHS
            authority: "https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0"
          },
          cache: {
            cacheLocation: "sessionStorage"
          }
        },
        accessToken: ""
      };
    },
    accessToken: (state) => {
      return state.accessToken;
    },
    selectedMenu: (state) => {
      return state.selectedMenu;
    },
    breadcrumb: (state) => {
      return state.breadcrumb;
    },
    showLoader: (state) => {
      return state.showLoader;
    },
    workingOnAlert: (state) => {
      return state.workingOnAlert;
    },
    alertType: (state) => {
      return state.alertType;
    }
  },
  actions: {
    accessToken(context, accessToken) {
      context.commit("accessToken", accessToken);
    },
    selectedMenu: (context, selectedMenu) => {
      context.commit("selectedMenu", selectedMenu);
    },
    breadcrumb(context, breadcrumb) {
      context.commit("breadcrumb", breadcrumb);
    },
    showLoader(context, showLoader) {
      context.commit("showLoader", showLoader);
    },
    workingOnAlert(context, workingOnAlert) {
      context.commit("workingOnAlert", workingOnAlert);
    }
  },
  mutations: {
    accessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    selectedMenu: (state, selectedMenu) => {
      state.selectedMenu = selectedMenu;
    },
    breadcrumb(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    showLoader(state, showLoader) {
      state.showLoader = showLoader;
    },
    workingOnAlert(state, workingOnAlert) {
      state.workingOnAlert = workingOnAlert;
    },
    alertType: (state, alertType) => {
      state.alertType = alertType
    },
    disableAlertType: (state, disableAlertType) => {
      state.disableAlertType = disableAlertType
    }
  }
});
