export default {
  ADMIN: "EHS_USER_ADMIN",
  ENGINEERINGMANAGER: "EHS_USER_PLANT_ENGINEERING_MANAGER",
  MAINTENANCEMANAGER: "EHS_USER_PLANT_MAINTENANCE_MANAGER",
  REGIONALENGINEER: "EHS_USER_REGIONAL_ENGINEER",
  PLANTMANAGER: "EHS_USER_PLANT_EHS_MANAGER",
  MANUFACTURINGDIRECTOR: "EHS_USER_MANUFACTURING_DIRECTOR",
  REGIONAL: "EHS_USER_REGIONAL",
  GLOBAL: "EHS_USER_GLOBAL_EHS",
  GLOBALRELIABILITY: "EHS_USER_GLOBAL_RELIABILITY",
  VIEWONLY: "EHS_USER_VIEW_ONLY",
  ADMINNAME: "Admin"
};
