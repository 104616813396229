<template>
  <div class="container-fluid footer-con d-flex">
    <div class="footer-sub-con">
      <span class="footer-title termscon">&copy; {{ new Date().getFullYear() }} EHS MSR System </span>
      <span class="footer-terms"> Email us for any support at </span>
      <a class="footer-title termscon" href="mailto:ehsmsr_support@goodyear.com"> ehsmsr_support@goodyear.com </a>
    </div>
    <div class="ft-12 footer-sub-con footer-logo-con"><img alt="Logo" src="./../assets/logo-footer.svg" /></div>
  </div>
</template>
<script>
  import customTokenCredential from "../CustomTokenProvider";
  import { mapGetters, mapMutations } from "vuex";

  export default {
    name: "FooterComponent",
    computed: {
      ...mapGetters(["selectedMenu", "accessToken", "stateMSAL"])
    },
    data() {
      return {
        account: undefined,
        containers: []
      };
    },
    async created() {
      console.log("footer");
      this.$emitter.on(
        "login",
        async function (account) {
          this.account = account;
          await this.getAzureStorageData();
        }.bind(this)
      );
      this.$emitter.on("logout", () => {
        console.log("logging out");
        this.account = undefined;
      });
    },
    methods: {
      ...mapMutations(["setAccessToken"]),
      jsonConcat(o1, o2) {
        for (let key in o2) {
          o1[key] = o2[key];
        }
        return o1;
      },
      async getAzureStorageData() {
        let tokenCredential = new customTokenCredential(this.$store.state.accessToken);
        console.log("customTokenCredential", tokenCredential);
      }
    }
  };
</script>
<style scoped>
  .footer-con {
    background: #f6f7f8;
    border: 1px solid #e5e6e8;
    color: #274ba7;
    position: fixed;
    bottom: 0px;
    z-index: 100;
    display: flex;
    padding: 1rem 4rem;
    width: calc(100% - 8rem);
    align-items: center;
  }
  .footer-terms {
    margin: 0 0.2rem 0 1rem;
  }
  .footer-sub-con {
    width: 50%;
  }

  .footer-logo-con {
    text-align: right;
  }

  .termscon {
    font-family: "BarlowB", sans-serif !important;
    color: #274ba7;
  }

  .my-pro-ft-mr {
    margin-top: 13rem !important;
  }
</style>
