//Custom Theme
const themeOverrides = {
  common: {
    fontFamily: ' "BarlowR", sans-serif ',
    fontFamilyMono: " sans-serif",
    fontSize: "12px",
    primaryColor: "#274BA7FF",
    primaryColorHover: "#3152A7FF",
    primaryColorPressed: "#1B43A9FF",
    primaryColorSuppl: "#274BA7FF",
    infoColor: "#274BA7FF",
    infoColorHover: "#274BA7FF",
    infoColorSuppl: "#274BA7FF"
  },
  Button: {
    colorSecondary: "rgba(255, 255, 255, 1)",
    colorSecondaryHover: "rgba(96, 145, 194, 0.09)",
    colorSecondaryPressed: "rgba(15, 71, 128, 0.13)",
    border: "1px solid #274BA7FF",
    borderHover: "1px solid #274BA7FF",
    borderPressed: "1px solid #274BA7FF",
    borderFocus: "1px solid #274BA7FF",
    borderDisabled: "1px solid #274ba0",
    colorInfo: "#274BA7FF",
    textColorTextInfo: " #274BA7FF",
    colorHoverInfo: "#274BA7FF",
    colorPressedInfo: "#274BA7FF",
    colorFocusInfo: "#274BA7FF",
    colorDisabledInfo: "#274BA7A6",
    textColorTextHoverInfo: "#274BA7FF",
    textColorTextPressedInfo: "#274BA7FF",
    textColorTextFocusInfo: "#274BA7FF",
    textColorTextDisabledInfo: "#274BA7FF",
    borderInfo: "1px solid #869ED8FF",
    borderHoverInfo: "1px solid #869ED8FF",
    borderPressedInfo: "1px solid #869ED8FF",
    borderFocusInfo: "1px solid #869ED8FF",
    borderDisabledInfo: "1px solid #869ED8FF",
    rippleColorInfo: "#274BA7FF",
    colorQuaternaryHover: "rgba(46, 51, 56, 0)",
    textColorGhostPressedInfo: "#274BA7FF"
  },
  Typography: {
    headerMargin1: "0"
  },
  Breadcrumb: {
    itemTextColor: "#274BA7FF",
    fontSize: "1.33rem",
    itemColorHover: "rgba(46, 51, 56, 0)",
    separatorColor: "#274BA7FF"
  },
  Select: {
    
  },
  Scrollbar: {
    color: "#274BA7FF",
    colorHover: "#274BA7FF"
  },
  DatePicker: {
    itemTextColor: "#313131FF",
    panelTextColor: "#313131FF",
    arrowColor: "#274BA7FF",
    calendarTitleTextColor: "#274BA7FF",
    calendarTitleColorHover: "#274BA7FF",
    iconColor: "#274BA7FF"
  },
  Checkbox: {
    labelPadding: "0 1rem",
    fontSizeLarge: "1.1666rem",
    border: "1px solid #747474FF",
    textColor: "#313131FF"
  },
  Dialog: {
    iconColorInfo: "#234372FF",
    padding: "45px 35px 68px  35px",
    closeMargin: "18px 18px 0 0",
    closeMarginIconTop: "18px 18px 0 0",
    fontSize: "16px",
    border: "1px solid #E6E6E6FF",
    textColor: "#234372FF",
    closeIconColor: "#A3A3A3FF",
    closeIconColorHover: "#A3A3A3EB",
    closeIconColorPressed: "#A3A3A3FF",
    closeBorderRadius: "6px",
    closeColorHover: "rgba(0, 0, 0, 0)",
    closeColorPressed: "rgba(0, 0, 0, 0)",
    borderRadius: "6px"
  },
  Spin: {
    color: "#274BA7FF"
  }
};
// use below mentioned buttons
// cancel or discard type btn ->  <n-button quaternary round type="info"
// submit, routing type btn ->  <n-button strong secondary round type="info"
// icon btn ->  <n-button quaternary circle type="info"
export default themeOverrides;
