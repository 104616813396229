<template>
  <n-space id="profile-container" class="profile-container">
    <div style="display: flex; justify-content: center; align-items: center">
      <n-avatar round size="small">
        {{ shortName }}
      </n-avatar>

      <n-dropdown trigger="hover" :options="options" @select="handleSelect">
        <n-button class="full-name" quaternary circle type="info">
          <n-text strong class="user-name">
            {{ fullName }}
          </n-text>
          <n-icon class="notification-icon" size="20" :component="CaretDownOutline"></n-icon>
        </n-button>
      </n-dropdown>
    </div>
  </n-space>
</template>

<script>
  import { NSpace, NIcon, NAvatar, NButton, NDropdown, NText } from "naive-ui";
  import { NotificationsOutline, CaretDownOutline } from "@vicons/ionicons5"; //https://www.xicons.org/#/
  import { mapGetters } from "vuex";
  import { PublicClientApplication } from "@azure/msal-browser";
  export default {
    name: "ProfileSection",
    components: {
      NSpace,
      NIcon,
      NAvatar,
      NButton,
      NDropdown,
      NText
    },
    data: () => ({
      NotificationsOutline,
      CaretDownOutline,
      options: [
        // { label: "User Profile",   key: "userProfile",  disabled: false},
        {
          label: "Logout",
          key: "log-out"
        }
      ]
    }),
    computed: {
      ...mapGetters(["selectedMenu", "loggedInUserData"]),
      shortName() {
        if (this.$oidc.userProfile) {
          return this.$oidc.userProfile.name[0] + "" + this.$oidc.userProfile.family_name[0];
        }
        return "";
      },
      fullName() {
        if (this.$oidc.userProfile) {
          return (
            this.$oidc.userProfile.given_name.slice(0, 1) +
            this.$oidc.userProfile.given_name.slice(1).toLowerCase() +
            " " +
            this.$oidc.userProfile.family_name.slice(0, 1) +
            this.$oidc.userProfile.family_name.slice(1).toLowerCase()
          );
        }
        return "";
      }
    },
    async created() {
      this.account = JSON.parse(sessionStorage.getItem("userProfile"));
    },
    methods: {
      handleSelect(key) {
        if (key === "log-out") this.SignOut();
        this.$router.push("/" + key);
      },

      async SignOut() {
        let config = this.$store.getters.stateMSAL.msalConfig;
        const myMsal = new PublicClientApplication(config);
        let homeAccountId = JSON.parse(sessionStorage.getItem("userProfile")).oid + "." + JSON.parse(sessionStorage.getItem("userProfile")).tid;
        // you can select which account application should sign out
        const logoutRequest = {
          account: myMsal.getAccountByHomeId(homeAccountId)
        };

        myMsal.logoutRedirect(logoutRequest);
      }
    }
  };
</script>
